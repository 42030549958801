<template>
  <b-alert
    show
    variant="info"
  >
    <div class="alert-body">
      <FeatherIcon icon="InfoIcon" />
      Taslak olarak kayıt edilen içerikler yönetici onayına gönderilmez.
    </div>
  </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'DraftSaveAlert',
  components: {
    BAlert,
  },
}
</script>
