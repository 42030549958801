<template>
  <b-tr>
    <b-th>Kira</b-th>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y1_kira"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          @input="planCalc"
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y2_kira"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          @input="planCalc"
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y3_kira"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          @input="planCalc"
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y1_kira_total"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          disabled
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y2_kira_total"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          disabled
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y3_kira_total"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          disabled
        />
      </b-input-group>
    </b-td>
  </b-tr>
</template>

<script>
import {
  BTr,
  BTh,
  BTd,
  BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Hammade',
  components: {
    BTr,
    BTh,
    BTd,
    Cleave,
    BInputGroup,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
  },
  methods: {
    planCalc() {
      /* Kira */
      const y1Kira = Number(this.financialPlan[1].items.y1_kira)
      this.financialPlan[1].items.y1_kira_total = y1Kira * 12
      const y2Kira = Number(this.financialPlan[1].items.y2_kira)
      this.financialPlan[1].items.y2_kira_total = y2Kira * 12
      const y3Kira = Number(this.financialPlan[1].items.y3_kira)
      this.financialPlan[1].items.y3_kira_total = y3Kira * 12
    },
  },
}
</script>

<style scoped>

</style>
