<template>
  <b-tr>
    <b-td>D. İşletme Gelirleri</b-td>
    <b-td>
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[4].items.y1_isletme_gelirleri"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          disabled
        />
      </b-input-group>
    </b-td>
    <b-td>
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[4].items.y2_isletme_gelirleri"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          disabled
        />
      </b-input-group>
    </b-td>
    <b-td>
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[4].items.y3_isletme_gelirleri"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          disabled
        />
      </b-input-group>
    </b-td>
  </b-tr>
</template>

<script>
import {
  BTr,
  BTd,
  BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'IsletmeGelirleri',
  components: {
    BTr,
    BTd,
    Cleave,
    BInputGroup,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
  },
}
</script>
<style scoped></style>
