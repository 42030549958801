<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th>{{ financialPlan[0].subtitle2 }}</b-th>
          <b-th>TUTAR</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>Kira Komisyonu - Depozito</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[0].items.kira_komisyonu_depozito"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                @input="planCalc"
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Makine ve Teçhizat Giderleri</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[0].items.makine_techizat_giderleri"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                @input="planCalc"
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Lisans, İzin, Ruhat vb</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[0].items.lisans_izin_ruhsat"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                @input="planCalc"
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Kuruluş İşlemleri ve Harç Masrafları</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[0].items.kurulus_islemleri"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                @input="planCalc"
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Genel Giderler</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[0].items.genel_giderler"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                @input="planCalc"
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Beklenmeyen Giderler</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[0].items.beklenmeyen_giderler"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                disabled
              />
            </b-input-group>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th class="align-middle">
            Toplam
          </b-th>
          <b-th>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[0].items.toplam_sabit_yatirim"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                disabled
              />
            </b-input-group>
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
  BTd,
  BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'PlanTable1',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
    Cleave,
    BInputGroup,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
  },
  methods: {
    planCalc() {
      let total = [
        Number(this.financialPlan[0].items.kira_komisyonu_depozito),
        Number(this.financialPlan[0].items.makine_techizat_giderleri),
        Number(this.financialPlan[0].items.lisans_izin_ruhsat),
        Number(this.financialPlan[0].items.kurulus_islemleri),
        // Number(this.financialPlan[0].items.genel_giderler),
      ]
      total = total.reduce((acc, val) => acc + val, 0)
      this.financialPlan[0].items.beklenmeyen_giderler = total * 0.1
      let total2 = [
        Number(this.financialPlan[0].items.kira_komisyonu_depozito),
        Number(this.financialPlan[0].items.makine_techizat_giderleri),
        Number(this.financialPlan[0].items.lisans_izin_ruhsat),
        Number(this.financialPlan[0].items.kurulus_islemleri),
        Number(this.financialPlan[0].items.genel_giderler),
        Number(this.financialPlan[0].items.beklenmeyen_giderler),
      ]
      total2 = total2.reduce((acc, val) => acc + val, 0)
      this.financialPlan[0].items.toplam_sabit_yatirim = total2
      this.financialPlan[5].items.y1_sabit_yatirim = total2
      this.financialPlan[3].items.baslangic_yatirimi = total2
    },
  },
}
</script>

<style scoped></style>
