import { render, staticRenderFns } from "./ElektrikSuYemek.vue?vue&type=template&id=c89cdd3c&scoped=true&"
import script from "./ElektrikSuYemek.vue?vue&type=script&lang=js&"
export * from "./ElektrikSuYemek.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c89cdd3c",
  null
  
)

export default component.exports