<template>
  <b-tr>
    <b-th>Elektrik Su Yemek vb</b-th>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y1_elektrik_su_yemek"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          @input="planCalc"
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y2_elektrik_su_yemek"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          @input="planCalc"
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y3_elektrik_su_yemek"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          @input="planCalc"
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y1_elektrik_su_yemek_total"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          disabled
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y2_elektrik_su_yemek_total"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          disabled
        />
      </b-input-group>
    </b-td>
    <b-td class="pl-1 pr-1">
      <b-input-group append="₺">
        <cleave
          v-model="financialPlan[1].items.y3_elektrik_su_yemek_total"
          class="form-control"
          :raw="true"
          :options="options.number"
          placeholder="0.00"
          disabled
        />
      </b-input-group>
    </b-td>
  </b-tr>
</template>

<script>
import {
  BTr,
  BTh,
  BTd,
  BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Hammade',
  components: {
    BTr,
    BTh,
    BTd,
    Cleave,
    BInputGroup,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
  },
  methods: {
    planCalc() {
      /* Elektrik Su Yemek */
      const y1ElektrikSuYemek = Number(this.financialPlan[1].items.y1_elektrik_su_yemek)
      this.financialPlan[1].items.y1_elektrik_su_yemek_total = y1ElektrikSuYemek * 12
      const y2ElektrikSuYemek = Number(this.financialPlan[1].items.y2_elektrik_su_yemek)
      this.financialPlan[1].items.y2_elektrik_su_yemek_total = y2ElektrikSuYemek * 12
      const y3ElektrikSuYemek = Number(this.financialPlan[1].items.y3_elektrik_su_yemek)
      this.financialPlan[1].items.y3_elektrik_su_yemek_total = y3ElektrikSuYemek * 12
    },
  },
}
</script>

<style scoped>

</style>
