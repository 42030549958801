<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
      fixed
    >
      <b-thead>
        <b-tr>
          <b-th class="align-middle font-small-2">
            {{ financialPlan[1].subtitle2 }}
          </b-th>
          <b-th class="align-middle text-center font-small-2 text-primary">
            1. YIL AYLIK ORTALAMA GİDELER
          </b-th>
          <b-th class="align-middle text-center font-small-2 text-primary">
            2. YIL AYLIK ORTALAMA GİDELER
          </b-th>
          <b-th class="align-middle text-center font-small-2 text-primary">
            3. YIL AYLIK ORTALAMA GİDELER
          </b-th>
          <b-th class="align-middle text-center font-small-2 text-primary">
            1. Yıl Toplam Harcama
          </b-th>
          <b-th class="align-middle text-center font-small-2 text-primary">
            2. Yıl Toplam Harcama
          </b-th>
          <b-th class="align-middle text-center font-small-2 text-primary">
            3. Yıl Toplam Harcama
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody class="font-small-2">
        <hammade />
        <kira />
        <elektrik-su-yemek />
        <pazarlama-tanitim />
        <haberlesme />
        <yakit />
        <personel12-aylik />
        <arge-hizmet-alimlari />
        <genel-giderler />
        <beklenmeyen-giderler />
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th>Toplam</b-th>
          <b-th>{{ financialPlan[1].items.y1_toplam | toCurrency }} ₺</b-th>
          <b-th>{{ financialPlan[1].items.y2_toplam | toCurrency }} ₺</b-th>
          <b-th>{{ financialPlan[1].items.y3_toplam | toCurrency }} ₺</b-th>
          <b-th>{{ financialPlan[1].items.y1_toplam_total | toCurrency }} ₺</b-th>
          <b-th>{{ financialPlan[1].items.y2_toplam_total | toCurrency }} ₺</b-th>
          <b-th>{{ financialPlan[1].items.y3_toplam_total | toCurrency }} ₺</b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
} from 'bootstrap-vue'
import Hammade from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/Hammade.vue'
import Kira from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/Kira.vue'
import ElektrikSuYemek from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/ElektrikSuYemek.vue'
import PazarlamaTanitim from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/PazarlamaTanitim.vue'
import Haberlesme from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/Haberlesme.vue'
import Yakit from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/Yakit.vue'
import Personel12Aylik from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/Personel12Aylik.vue'
import ArgeHizmetAlimlari from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/ArgeHizmetAlimlari.vue'
import GenelGiderler from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/GenelGiderler.vue'
import BeklenmeyenGiderler from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2/BeklenmeyenGiderler.vue'

export default {
  name: 'PlanTable1',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    Hammade,
    Kira,
    ElektrikSuYemek,
    PazarlamaTanitim,
    Haberlesme,
    Yakit,
    Personel12Aylik,
    ArgeHizmetAlimlari,
    GenelGiderler,
    BeklenmeyenGiderler,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
  },
}
</script>

<style scoped></style>
