<template>
  <div>
    <startup-card />
    <result-card
      v-if="financialPlanSaveStatus.status !== null"
      :result="financialPlanSaveStatus"
    />
    <validation-observer
      v-else
      ref="simpleRules"
    >
      <template v-if="financialPlan.length">
        <b-card
          v-for="(plan) in financialPlan"
          :key="plan.id"
        >
          <b-card-title class="mb-1">
            {{ plan.title }}
          </b-card-title>
          <b-card-sub-title>{{ plan.subtitle }}</b-card-sub-title>
          <p class="font-small-3 text-primary font-italic">
            {{ plan.subtitle2 }}
          </p>
          <div v-if="plan.id === '1'">
            <plan-table1 />
          </div>
          <div v-if="plan.id === '2'">
            <plan-table2 />
          </div>
          <div v-if="plan.id === '3'">
            <plan-table3 />
          </div>
          <div v-if="plan.id === '4'">
            <plan-table4 />
          </div>
          <div v-if="plan.id === '5'">
            <plan-table5 />
          </div>
          <div v-if="plan.id === '6'">
            <plan-table6 />
          </div>
        </b-card>
        <div>
          <b-form-checkbox
            v-model="draftStatus"
            class="mb-1"
            switch
          >
            {{ $t('Taslak Olarak Kaydet') }}
          </b-form-checkbox>
          <draft-save-alert v-if="draftStatus" />
        </div>
        <template v-if="draftStatus">
          <b-button
            variant="warning"
            @click="saveDraft"
          >
            {{ $t('Taslak Olarak Kaydet') }}
          </b-button>
        </template>
        <template v-else>
          <b-button
            variant="primary"
            @click="saveData"
          >
            {{ $t('Gönder') }}
          </b-button>
        </template>
      </template>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import StartupCard from '@/views/App/Interests/Requests/components/StartupCard.vue'
import ResultCard from '@/views/App/NewStartups/ResultCard.vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import PlanTable1 from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable1.vue'
import PlanTable2 from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable2.vue'
import PlanTable3 from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable3.vue'
import PlanTable4 from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable4.vue'
import PlanTable5 from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable5.vue'
import PlanTable6 from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable6.vue'
import DraftSaveAlert from '@/views/App/NewStartups/components/DraftSaveAlert.vue'

export default {
  name: 'FinancialPlanning',
  components: {
    DraftSaveAlert,
    BFormCheckbox,
    ValidationObserver,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BButton,
    StartupCard,
    ResultCard,
    PlanTable1,
    PlanTable2,
    PlanTable3,
    PlanTable4,
    PlanTable5,
    PlanTable6,
  },
  data() {
    return {
      required,
      draftStatus: false,
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
    financialPlanSaveStatus() {
      return this.$store.getters['financialPlan/getFinancialPlanSaveStatus']
    },
    fpResult() {
      return this.$store.getters['financialPlan/getFpResult']
    },
  },
  watch: {
    financialPlan: {
      deep: true,
      handler(val) {
        this.financialPlan[0].id_startups = this.$route.params.id
        this.financialPlan[1].id_startups = this.$route.params.id
        this.financialPlan[2].id_startups = this.$route.params.id
        this.financialPlan[3].id_startups = this.$route.params.id
        this.financialPlan[4].id_startups = this.$route.params.id
        this.financialPlan[5].id_startups = this.$route.params.id
        if (val[1].items) {
          let total = [
            Number(this.financialPlan[1].items.y1_hammade_maliyeti),
            Number(this.financialPlan[1].items.y1_kira),
            Number(this.financialPlan[1].items.y1_elektrik_su_yemek),
            Number(this.financialPlan[1].items.y1_pazarlama_tanitim),
            Number(this.financialPlan[1].items.y1_haberlesme),
            Number(this.financialPlan[1].items.y1_yakit),
            Number(this.financialPlan[1].items.y1_personel_12aylik),
            Number(this.financialPlan[1].items.y1_arge_hizmet_alimlari),
            Number(this.financialPlan[1].items.y1_genel_giderler),
          ]
          total = total.reduce((acc, value) => acc + value, 0)
          this.financialPlan[1].items.y1_beklenmeyen_giderler = total * 0.1
          this.financialPlan[1].items.y1_toplam = total + this.financialPlan[1].items.y1_beklenmeyen_giderler
          this.financialPlan[1].items.y1_beklenmeyen_giderler_total = (total * 0.1) * 12
          this.financialPlan[1].items.y1_toplam_total = (this.financialPlan[1].items.y1_toplam * 12) - (Number(this.financialPlan[1].items.y1_yakit) * 12)
          this.financialPlan[3].items.isletme_sermayesi = this.financialPlan[1].items.y1_toplam_total / 1.31
          this.financialPlan[5].items.y1_isletme_sermayesi = this.financialPlan[1].items.y1_toplam_total
          let total2 = [
            Number(this.financialPlan[1].items.y2_hammade_maliyeti),
            Number(this.financialPlan[1].items.y2_kira),
            Number(this.financialPlan[1].items.y2_elektrik_su_yemek),
            Number(this.financialPlan[1].items.y2_pazarlama_tanitim),
            Number(this.financialPlan[1].items.y2_haberlesme),
            Number(this.financialPlan[1].items.y2_yakit),
            Number(this.financialPlan[1].items.y2_personel_12aylik),
            Number(this.financialPlan[1].items.y2_arge_hizmet_alimlari),
            Number(this.financialPlan[1].items.y2_genel_giderler),
          ]
          total2 = total2.reduce((acc, value) => acc + value, 0)
          this.financialPlan[1].items.y2_beklenmeyen_giderler = total2 * 0.1
          this.financialPlan[1].items.y2_toplam = total2 + this.financialPlan[1].items.y2_beklenmeyen_giderler
          this.financialPlan[1].items.y2_beklenmeyen_giderler_total = (((total2 - (Number(this.financialPlan[1].items.y2_yakit))) * 0.1) * 12)
          this.financialPlan[1].items.y2_toplam_total = [
            Number(this.financialPlan[1].items.y2_hammade_maliyeti) * 12,
            Number(this.financialPlan[1].items.y2_kira) * 12,
            Number(this.financialPlan[1].items.y2_elektrik_su_yemek) * 12,
            Number(this.financialPlan[1].items.y2_pazarlama_tanitim) * 12,
            Number(this.financialPlan[1].items.y2_haberlesme) * 12,
            Number(this.financialPlan[1].items.y2_personel_12aylik) * 12,
            Number(this.financialPlan[1].items.y2_arge_hizmet_alimlari) * 12,
            Number(this.financialPlan[1].items.y2_genel_giderler) * 12,
          ].reduce((acc, value) => acc + value, 0) + this.financialPlan[1].items.y2_beklenmeyen_giderler_total
          this.financialPlan[5].items.y2_isletme_sermayesi = this.financialPlan[1].items.y2_toplam_total
          let total3 = [
            Number(this.financialPlan[1].items.y3_hammade_maliyeti),
            Number(this.financialPlan[1].items.y3_kira),
            Number(this.financialPlan[1].items.y3_elektrik_su_yemek),
            Number(this.financialPlan[1].items.y3_pazarlama_tanitim),
            Number(this.financialPlan[1].items.y3_haberlesme),
            Number(this.financialPlan[1].items.y3_yakit),
            Number(this.financialPlan[1].items.y3_personel_12aylik),
            Number(this.financialPlan[1].items.y3_arge_hizmet_alimlari),
            Number(this.financialPlan[1].items.y3_genel_giderler),
          ]
          total3 = total3.reduce((acc, value) => acc + value, 0)
          this.financialPlan[1].items.y3_beklenmeyen_giderler = total3 * 0.1
          this.financialPlan[1].items.y3_toplam = total3 + this.financialPlan[1].items.y3_beklenmeyen_giderler
          this.financialPlan[1].items.y3_beklenmeyen_giderler_total = (((total3 - (Number(this.financialPlan[1].items.y3_yakit))) * 0.1) * 12)
          this.financialPlan[1].items.y3_toplam_total = [
            Number(this.financialPlan[1].items.y3_hammade_maliyeti) * 12,
            Number(this.financialPlan[1].items.y3_kira) * 12,
            Number(this.financialPlan[1].items.y3_elektrik_su_yemek) * 12,
            Number(this.financialPlan[1].items.y3_pazarlama_tanitim) * 12,
            Number(this.financialPlan[1].items.y3_haberlesme) * 12,
            Number(this.financialPlan[1].items.y3_personel_12aylik) * 12,
            Number(this.financialPlan[1].items.y3_arge_hizmet_alimlari) * 12,
            Number(this.financialPlan[1].items.y3_genel_giderler) * 12,
          ].reduce((acc, value) => acc + value, 0) + this.financialPlan[1].items.y3_beklenmeyen_giderler_total
          this.financialPlan[5].items.y3_isletme_sermayesi = this.financialPlan[1].items.y3_toplam_total
        }
        if (val[3].items) {
          this.nakitGirisleriy1()
          this.nakitGirisleriy2()
          this.nakitGirisleriy3()
        }
        this.vergiY1()
        this.vergiY2()
        this.vergiY3()
        this.nakitCikisToplami()
        this.yilSonuEldekiNakit()
      },
    },
  },
  created() {
    localize(this.$i18n.locale)
    this.getFinancialPlan()
    this.getStartup()
  },
  methods: {
    getStartup() {
      this.$store.dispatch('memberStartups/memberStartupView', this.$route.params.id)
    },
    getFinancialPlan() {
      this.$store.dispatch('financialPlan/financialPlan', {
        id_startups: this.$route.params.id,
      })
    },
    nakitGirisleriy1() {
      this.financialPlan[4].items.y1_hibe = this.financialPlan[3].items.hibe
      this.financialPlan[4].items.y1_ozkaynak = this.financialPlan[3].items.ozkaynaklar
      this.financialPlan[4].items.y1_yatirim_borc = this.financialPlan[3].items.yatirim_borc
      this.financialPlan[4].items.y1_yilbasi_eldeki_nakit = 0
      let totalSale = []
      this.financialPlan[2].items.forEach(e => {
        totalSale.push(Number(e.y1_sale_price) * Number(e.y1_sale_quantity))
      })
      totalSale = totalSale.reduce((acc, value) => acc + value, 0)
      this.financialPlan[4].items.y1_isletme_gelirleri = totalSale
      let nakitGirisToplam = [
        Number(this.financialPlan[4].items.y1_hibe),
        Number(this.financialPlan[4].items.y1_ozkaynak),
        Number(this.financialPlan[4].items.y1_yatirim_borc),
        Number(this.financialPlan[4].items.y1_isletme_gelirleri),
        Number(this.financialPlan[4].items.y1_yilbasi_eldeki_nakit),
      ]
      nakitGirisToplam = nakitGirisToplam.reduce((acc, value) => acc + value, 0)
      this.financialPlan[4].items.y1_nakit_girisleri_toplami = nakitGirisToplam
    },
    nakitGirisleriy2() {
      // this.financialPlan[4].items.y2_hibe = this.financialPlan[3].items.hibe
      this.financialPlan[4].items.y2_ozkaynak = 0
      // this.financialPlan[4].items.y2_yatirim_borc = this.financialPlan[3].items.yatirim_borc
      let totalSale2 = []
      this.financialPlan[2].items.forEach(e => {
        totalSale2.push(Number(e.y2_sale_price) * Number(e.y2_sale_quantity))
      })
      totalSale2 = totalSale2.reduce((acc, value) => acc + value, 0)
      this.financialPlan[4].items.y2_isletme_gelirleri = totalSale2
      let nakitGirisToplam = [
        Number(this.financialPlan[4].items.y2_hibe),
        Number(this.financialPlan[4].items.y2_ozkaynak),
        Number(this.financialPlan[4].items.y2_yatirim_borc),
        Number(this.financialPlan[4].items.y2_isletme_gelirleri),
        Number(this.financialPlan[4].items.y2_yilbasi_eldeki_nakit),
      ]
      nakitGirisToplam = nakitGirisToplam.reduce((acc, value) => acc + value, 0)
      this.financialPlan[4].items.y2_nakit_girisleri_toplami = nakitGirisToplam
    },
    nakitGirisleriy3() {
      // this.financialPlan[4].items.y3_hibe = this.financialPlan[3].items.hibe
      this.financialPlan[4].items.y3_ozkaynak = 0
      // this.financialPlan[4].items.y3_yatirim_borc = this.financialPlan[3].items.yatirim_borc
      let totalSale3 = []
      this.financialPlan[2].items.forEach(e => {
        totalSale3.push(Number(e.y3_sale_price) * Number(e.y3_sale_quantity))
      })
      totalSale3 = totalSale3.reduce((acc, value) => acc + value, 0)
      this.financialPlan[4].items.y3_isletme_gelirleri = totalSale3
      let nakitGirisToplam = [
        Number(this.financialPlan[4].items.y3_hibe),
        Number(this.financialPlan[4].items.y3_ozkaynak),
        Number(this.financialPlan[4].items.y3_yatirim_borc),
        Number(this.financialPlan[4].items.y3_isletme_gelirleri),
        Number(this.financialPlan[4].items.y3_yilbasi_eldeki_nakit),
      ]
      nakitGirisToplam = nakitGirisToplam.reduce((acc, value) => acc + value, 0)
      this.financialPlan[4].items.y3_nakit_girisleri_toplami = nakitGirisToplam
    },
    vergiY1() {
      /* 1. Yıl Vergiler Hesaplanıyor */
      // this.financialPlan[4].items.y1_isletme_gelirleri
      // this.financialPlan[1].items.y1_toplam_total
      // this.financialPlan[0].items.makine_techizat_giderleri / 10
      // this.financialPlan[5].items.y1_borc_kredi_faiz
      let vergiHesap1 = 0
      let vergiHesap2 = 0
      let vergiHesap3 = 0
      let vergiHesap4 = 0
      let vergi = 0
      if (this.financialPlan[4].items.y1_isletme_gelirleri) {
        vergiHesap1 = Number(this.financialPlan[4].items.y1_isletme_gelirleri)
      }
      if (this.financialPlan[1].items.y1_toplam_total) {
        vergiHesap2 = Number(this.financialPlan[1].items.y1_toplam_total)
      }
      if (this.financialPlan[0].items.makine_techizat_giderleri) {
        vergiHesap3 = Number(this.financialPlan[0].items.makine_techizat_giderleri) / 10
      }
      if (this.financialPlan[5].items.y1_borc_kredi_faiz) {
        vergiHesap4 = Number(this.financialPlan[5].items.y1_borc_kredi_faiz)
      }
      const vergiMatrahi = vergiHesap1 - vergiHesap2 - vergiHesap3 - vergiHesap4
      if (vergiMatrahi > 0) {
        vergi = vergiMatrahi * 0.2
      }
      this.financialPlan[5].items.y1_vergiler = vergi
      /* 1. Yıl Vergiler Hesaplanıyor */
    },
    vergiY2() {
      /* 2. Yıl Vergiler Hesaplanıyor */
      let vergiHesap1 = 0
      let vergiHesap2 = 0
      let vergiHesap3 = 0
      let vergiHesap4 = 0
      let vergi = 0
      if (this.financialPlan[4].items.y2_isletme_gelirleri) {
        vergiHesap1 = Number(this.financialPlan[4].items.y2_isletme_gelirleri)
      }
      if (this.financialPlan[1].items.y2_toplam_total) {
        vergiHesap2 = Number(this.financialPlan[1].items.y2_toplam_total)
      }
      if (this.financialPlan[0].items.makine_techizat_giderleri) {
        vergiHesap3 = Number(this.financialPlan[0].items.makine_techizat_giderleri) / 10
      }
      if (this.financialPlan[5].items.y2_borc_kredi_faiz) {
        vergiHesap4 = Number(this.financialPlan[5].items.y2_borc_kredi_faiz)
      }
      const vergiMatrahi = vergiHesap1 - vergiHesap2 - vergiHesap3 - vergiHesap4
      if (vergiMatrahi > 0) {
        vergi = vergiMatrahi * 0.2
      }
      this.financialPlan[5].items.y2_vergiler = vergi
      /* 2. Yıl Vergiler Hesaplanıyor */
    },
    vergiY3() {
      /* 3. Yıl Vergiler Hesaplanıyor */
      let vergiHesap1 = 0
      let vergiHesap2 = 0
      let vergiHesap3 = 0
      let vergiHesap4 = 0
      let vergi = 0
      if (this.financialPlan[4].items.y3_isletme_gelirleri) {
        vergiHesap1 = Number(this.financialPlan[4].items.y3_isletme_gelirleri)
      }
      if (this.financialPlan[1].items.y3_toplam_total) {
        vergiHesap2 = Number(this.financialPlan[1].items.y3_toplam_total)
      }
      if (this.financialPlan[0].items.makine_techizat_giderleri) {
        vergiHesap3 = Number(this.financialPlan[0].items.makine_techizat_giderleri) / 10
      }
      if (this.financialPlan[5].items.y3_borc_kredi_faiz) {
        vergiHesap4 = Number(this.financialPlan[5].items.y3_borc_kredi_faiz)
      }
      const vergiMatrahi = vergiHesap1 - vergiHesap2 - vergiHesap3 - vergiHesap4
      if (vergiMatrahi > 0) {
        vergi = vergiMatrahi * 0.2
      }
      this.financialPlan[5].items.y3_vergiler = vergi
      /* 3. Yıl Vergiler Hesaplanıyor */
    },
    nakitCikisToplami() {
      let totalY1 = [
        Number(this.financialPlan[5].items.y1_sabit_yatirim),
        Number(this.financialPlan[5].items.y1_isletme_sermayesi),
        Number(this.financialPlan[5].items.y1_borc_kredi_faiz),
        Number(this.financialPlan[5].items.y1_borc_kredi_anapara),
        Number(this.financialPlan[5].items.y1_vergiler),
      ]
      totalY1 = totalY1.reduce((acc, value) => acc + value, 0)
      this.financialPlan[5].items.y1_nakit_cikis_toplam = totalY1
      let totalY2 = [
        Number(this.financialPlan[5].items.y2_sabit_yatirim),
        Number(this.financialPlan[5].items.y2_isletme_sermayesi),
        Number(this.financialPlan[5].items.y2_borc_kredi_faiz),
        Number(this.financialPlan[5].items.y2_borc_kredi_anapara),
        Number(this.financialPlan[5].items.y2_vergiler),
      ]
      totalY2 = totalY2.reduce((acc, value) => acc + value, 0)
      this.financialPlan[5].items.y2_nakit_cikis_toplam = totalY2
      let totalY3 = [
        Number(this.financialPlan[5].items.y3_sabit_yatirim),
        Number(this.financialPlan[5].items.y3_isletme_sermayesi),
        Number(this.financialPlan[5].items.y3_borc_kredi_faiz),
        Number(this.financialPlan[5].items.y3_borc_kredi_anapara),
        Number(this.financialPlan[5].items.y3_vergiler),
      ]
      totalY3 = totalY3.reduce((acc, value) => acc + value, 0)
      this.financialPlan[5].items.y3_nakit_cikis_toplam = totalY3
    },
    yilSonuEldekiNakit() {
      const nakitGirisY1 = this.financialPlan[4].items.y1_nakit_girisleri_toplami
      const nakitGirisY2 = this.financialPlan[4].items.y2_nakit_girisleri_toplami
      const nakitGirisY3 = this.financialPlan[4].items.y3_nakit_girisleri_toplami
      const nakitCikisY1 = this.financialPlan[5].items.y1_nakit_cikis_toplam
      const nakitCikisY2 = this.financialPlan[5].items.y2_nakit_cikis_toplam
      const nakitCikisY3 = this.financialPlan[5].items.y3_nakit_cikis_toplam
      this.financialPlan[5].items.y1_yilsonu_nakit = Number(nakitGirisY1) - Number(nakitCikisY1)
      this.financialPlan[5].items.y2_yilsonu_nakit = Number(nakitGirisY2) - Number(nakitCikisY2)
      this.financialPlan[5].items.y3_yilsonu_nakit = Number(nakitGirisY3) - Number(nakitCikisY3)
      this.financialPlan[4].items.y2_yilbasi_eldeki_nakit = this.financialPlan[5].items.y1_yilsonu_nakit
      this.financialPlan[4].items.y3_yilbasi_eldeki_nakit = this.financialPlan[5].items.y2_yilsonu_nakit
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('financialPlan/saveData', this.financialPlan)
        }
      })
    },
    saveDraft() {
      this.$store.dispatch('financialPlan/saveDraft', this.financialPlan)
    },
  },
}
</script>
<style scoped></style>
