<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
      fixed
    >
      <b-thead>
        <b-tr>
          <b-th>{{ financialPlan[2].subtitle2 }}</b-th>
          <b-th>1. Yıl Satış Fiyatı</b-th>
          <b-th>1. Yıl Satış Miktarı</b-th>
          <b-th>2. Yıl Satış Fiyatı</b-th>
          <b-th>2. Yıl Satış Miktarı</b-th>
          <b-th>3. Yıl Satış Fiyatı</b-th>
          <b-th>3. Yıl Satış Miktarı</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item,key) in financialPlan[2].items"
          :key="key"
        >
          <b-td class="pl-1 pr-1">
            <b-form-input
              v-model="item.y1_title"
              :placeholder="financialPlan[2].subtitle2"
            />
          </b-td>
          <b-td class="pl-1 pr-1">
            <b-input-group append="₺">
              <cleave
                v-model="item.y1_sale_price"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
              />
            </b-input-group>
          </b-td>
          <b-td class="pl-1 pr-1">
            <cleave
              v-model="item.y1_sale_quantity"
              class="form-control"
              :raw="true"
              :options="options.number"
              placeholder="0"
            />
          </b-td>
          <b-td class="pl-1 pr-1">
            <b-input-group append="₺">
              <cleave
                v-model="item.y2_sale_price"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
              />
            </b-input-group>
          </b-td>
          <b-td class="pl-1 pr-1">
            <cleave
              v-model="item.y2_sale_quantity"
              class="form-control"
              :raw="true"
              :options="options.number"
              placeholder="0"
            />
          </b-td>
          <b-td class="pl-1 pr-1">
            <b-input-group append="₺">
              <cleave
                v-model="item.y3_sale_price"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
              />
            </b-input-group>
          </b-td>
          <b-td class="pl-1 pr-1">
            <cleave
              v-model="item.y3_sale_quantity"
              class="form-control"
              :raw="true"
              :options="options.number"
              placeholder="0"
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-button
      variant="primary"
      class="mt-2"
      @click="addRow"
    >
      <FeatherIcon icon="PlusIcon" />
      Ürün Hizmet Ekle
    </b-button>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BInputGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'PlanTable3',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    Cleave,
    BInputGroup,
    BFormInput,
    BButton,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
  },
  methods: {
    addRow() {
      this.financialPlan[2].items.push({
        y1_title: null,
        y1_sale_price: null,
        y1_sale_quantity: null,
        y2_title: null,
        y2_sale_price: null,
        y2_sale_quantity: null,
        y3_title: null,
        y3_sale_price: null,
        y3_sale_quantity: null,
        id_startups: null,
      })
    },
  },
}
</script>

<style scoped></style>
