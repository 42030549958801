import { render, staticRenderFns } from "./Yakit.vue?vue&type=template&id=ecee6c40&scoped=true&"
import script from "./Yakit.vue?vue&type=script&lang=js&"
export * from "./Yakit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecee6c40",
  null
  
)

export default component.exports