<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th colspan="2">
            {{ financialPlan[3].title }}
          </b-th>
        </b-tr>
        <b-tr>
          <b-th />
          <b-th>1. Yıl Toplamı</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th colspan="2">
            Finansman İhtiyacı
          </b-th>
        </b-tr>
        <b-tr>
          <b-td>1. Başlangıç Yatırımı</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[3].items.baslangic_yatirimi"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                disabled
                @input="calcTotal"
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>2. İşletme Sermayesi</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[3].items.isletme_sermayesi"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                disabled
                @input="calcTotal"
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Toplam Finansman İhtiyacı</b-th>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[3].items.toplam_finansman_ihtiyaci"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                disabled
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th colspan="2">
            Finansman Kaynakları
          </b-th>
        </b-tr>
        <b-tr>
          <b-td>1. Özkaynaklar</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[3].items.ozkaynaklar"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                @input="planCalc"
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>2. Hibe (Kosgeb - Tübikat vb)</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[3].items.hibe"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                @input="planCalc"
              />
            </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>3. Yatırım - Borç</b-td>
          <b-td>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[3].items.yatirim_borc"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                @input="planCalc"
              />
            </b-input-group>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th class="align-middle">
            Toplam Finansman
          </b-th>
          <b-th>
            <b-input-group append="₺">
              <cleave
                v-model="financialPlan[3].items.toplam_finansman"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="0.00"
                disabled
              />
            </b-input-group>
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
  BTd,
  BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'PlanTable4',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
    Cleave,
    BInputGroup,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
  },
  methods: {
    calcTotal() {
      let total = [
        Number(this.financialPlan[3].items.baslangic_yatirimi),
        Number(this.financialPlan[3].items.isletme_sermayesi),
      ]
      total = total.reduce((acc, val) => acc + val, 0)
      this.financialPlan[3].items.toplam_finansman_ihtiyaci = total
    },
    planCalc() {
      let total = [
        Number(this.financialPlan[3].items.ozkaynaklar),
        Number(this.financialPlan[3].items.hibe),
        Number(this.financialPlan[3].items.yatirim_borc),
      ]
      total = total.reduce((acc, val) => acc + val, 0)
      this.financialPlan[3].items.toplam_finansman = total
    },
  },
}
</script>

<style scoped></style>
