<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th>{{ financialPlan[5].subtitle2 }}</b-th>
          <b-th>1. Yıl</b-th>
          <b-th>2. Yıl</b-th>
          <b-th>3. Yıl</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <sabit-yatirim-toplami />
        <isletme-sermayesi />
        <borc-kredi-faiz />
        <borc-kredi-anapara />
        <vergiler />
      </b-tbody>
      <b-tfoot>
        <nakit-cikis-toplam />
        <yilsonu-nakit />
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
} from 'bootstrap-vue'
import SabitYatirimToplami from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable6/SabitYatirimToplami.vue'
import IsletmeSermayesi from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable6/IsletmeSermayesi.vue'
import BorcKrediFaiz from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable6/BorcKrediFaiz.vue'
import BorcKrediAnapara from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable6/BorcKrediAnapara.vue'
import Vergiler from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable6/Vergiler.vue'
import NakitCikisToplam from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable6/NakitCikisToplam.vue'
import YilsonuNakit from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable6/YilsonuNakit.vue'

export default {
  name: 'PlanTable6',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    SabitYatirimToplami,
    IsletmeSermayesi,
    BorcKrediFaiz,
    BorcKrediAnapara,
    Vergiler,
    NakitCikisToplam,
    YilsonuNakit,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
  },
}
</script>

<style scoped></style>
