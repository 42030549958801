<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th>{{ financialPlan[4].subtitle2 }}</b-th>
          <b-th>1. Yıl</b-th>
          <b-th>2. Yıl</b-th>
          <b-th>3. Yıl</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <hibe-destekleri />
        <ozkaynak />
        <yatirim-borc />
        <isletme-gelirleri />
        <yilbasi-eldeki-nakit />
      </b-tbody>
      <b-tfoot>
        <nakit-girisleri-toplami />
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
} from 'bootstrap-vue'
import HibeDestekleri from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable5/HibeDestekleri.vue'
import Ozkaynak from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable5/Ozkaynak.vue'
import YatirimBorc from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable5/YatirimBorc.vue'
import IsletmeGelirleri from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable5/IsletmeGelirleri.vue'
import YilbasiEldekiNakit from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable5/YilbasiEldekiNakit.vue'
import NakitGirisleriToplami from '@/views/App/NewStartups/FinancialPlanning/tables/PlanTable5/NakitGirisleriToplami.vue'

export default {
  name: 'PlanTable5',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    HibeDestekleri,
    Ozkaynak,
    YatirimBorc,
    IsletmeGelirleri,
    YilbasiEldekiNakit,
    NakitGirisleriToplami,
  },
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    financialPlan() {
      return this.$store.getters['financialPlan/getFinancialPlan']
    },
  },
}
</script>

<style scoped></style>
